import type { VendorMediaKey } from '@components/unified-lightbox/atoms';
import { getFilterByMediaType } from '@components/unified-lightbox/helpers';
import { useLiteStorefront } from '@hooks/use-lite-storefront';
import classnames from 'classnames';
import React, { type FC } from 'react';
import { connect } from 'react-redux';
import { LiteStorefrontGallery } from './LiteStorefrontGallery';
import GalleryPlaceHolder from './components/GalleryPlaceholder';
import { MediaSummary } from './components/MediaSummary/MediaSummary';
import SeeAllPhotosCTA from './components/SeeAllPhotosCTA';
import Media from './containers/Media';
import {
	getOrientationsKey,
	gridTemplateImgSizes,
	gridTemplateMap,
} from './grid_templates';
import { selectDerivedMedia } from './selectors';
import Styles from './styles.scss';

export interface GalleryProps {
	lazy?: boolean;
	media: Vendor.Media[] | null;
	mediaCount: number;
	onMediaClick?: (index: number) => void;
	onSeeAllClick?: () => void;
	onVirtualTourClick?: () => void;
	openUnifiedLightbox?:
		| ((overview: boolean, index: number, filterValue?: VendorMediaKey) => void)
		| null;
}

const Gallery: FC<GalleryProps> = ({
	lazy = false,
	onSeeAllClick,
	onVirtualTourClick,
	onMediaClick,
	openUnifiedLightbox,
	media,
	mediaCount,
}) => {
	const indexedMedia = media?.map((m, index) => ({ index, ...m })) || [];
	const isVirtualTourOnGrid = indexedMedia
		.slice(0, 5)
		.some((element) => element.mediaType === '360TOUR');
	const isVideoOnGrid = indexedMedia
		.slice(0, 5)
		.some(
			(element) =>
				element.mediaType === 'VIDEO' || element.mediaType === 'VIDEO_UPLOAD',
		);
	const orientationsKey = getOrientationsKey(media?.slice(0, 5) || []);
	const gridTemplate = gridTemplateMap[orientationsKey] || 'template1';

	const wrappedOpenUnifiedLightbox = (
		overview: boolean,
		filterValue?: VendorMediaKey,
	) =>
		openUnifiedLightbox
			? (index: number) => {
					return openUnifiedLightbox?.(overview, index, filterValue);
				}
			: undefined;

	const isLiteStorefront = useLiteStorefront();
	if (isLiteStorefront) {
		return (
			<LiteStorefrontGallery
				media={indexedMedia}
				onMediaClick={
					wrappedOpenUnifiedLightbox(
						false,
						getFilterByMediaType(indexedMedia[0]?.mediaType),
					) || onMediaClick
				}
				onSeeAllClick={wrappedOpenUnifiedLightbox(true, 'all') || onSeeAllClick}
			/>
		);
	}

	return (
		<div
			data-testid="mosaic-grid"
			className={classnames(Styles.galleryGrid, Styles[gridTemplate])}
		>
			{mediaCount > 0
				? indexedMedia.slice(0, 5).map((mediaItem, index) => {
						return (
							<Media
								key={mediaItem.index}
								lazy={lazy}
								onMediaClick={wrappedOpenUnifiedLightbox(false) || onMediaClick}
								index={mediaItem.index}
								mediaItem={mediaItem}
								width={gridTemplateImgSizes[gridTemplate][index].width}
								height={gridTemplateImgSizes[gridTemplate][index].height}
								smartCrop
								preload
							/>
						);
					})
				: [0, 1, 2, 3, 4].map((i) => <GalleryPlaceHolder key={i} />)}
			<div className={Styles.summary}>
				<MediaSummary
					mediaItems={indexedMedia}
					isVirtualTourOnGrid={isVirtualTourOnGrid}
					isVideoOnGrid={isVideoOnGrid}
					onSeeAllClick={onSeeAllClick}
					onVirtualTourClick={onVirtualTourClick}
					openUnifiedLightboxVideos={wrappedOpenUnifiedLightbox(
						indexedMedia.filter(
							(media) =>
								media.mediaType === 'VIDEO' ||
								media.mediaType === 'VIDEO_UPLOAD',
						).length > 1,
						'videos',
					)}
					openUnifiedLightboxTours={wrappedOpenUnifiedLightbox(
						indexedMedia.filter((media) => media.mediaType === '360TOUR')
							.length > 1,
						'tours',
					)}
				/>
			</div>
			<div className={Styles.ctaGroup}>
				{mediaCount > 0 && (
					<SeeAllPhotosCTA
						onClick={wrappedOpenUnifiedLightbox(true, 'all') || onSeeAllClick}
						count={mediaCount}
					/>
				)}
			</div>
		</div>
	);
};

export const mapStateToProps = (state: Redux.State) => ({
	media: selectDerivedMedia(state),
	mediaCount: state.vendor.vendorRaw?.mediaSummary.media?.length || 0,
});

export default connect(mapStateToProps)(Gallery);

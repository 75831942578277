import MediaNotAvailable from '@components/media_not_available';
import React from 'react';
import type { IndexedMedia } from 'types/vendor';
import SeeAllPhotosCTA from './components/SeeAllPhotosCTA';
import Media from './containers/Media';
import Styles from './lite-storefront-gallery.scss';

export const dimensionProps = { width: 1280, height: 720 } as const;

interface LiteStorefrontGalleryProps {
	media: IndexedMedia[];
	onMediaClick?: (index: number) => void;
	onSeeAllClick?: () => void;
}

export const LiteStorefrontGallery = ({
	media,
	onMediaClick,
	onSeeAllClick,
}: LiteStorefrontGalleryProps) => {
	const firstMediaItem = media[0];
	const mediaCount = media.length;
	return (
		<div className={Styles.liteGalleryImageColumn}>
			{firstMediaItem ? (
				<Media
					onMediaClick={onMediaClick}
					index={firstMediaItem.index}
					mediaItem={firstMediaItem}
					lazy={false}
					smartCrop
					{...dimensionProps}
				/>
			) : (
				<MediaNotAvailable />
			)}
			{mediaCount > 0 && (
				<div className={Styles.ctaGroup}>
					<SeeAllPhotosCTA onClick={onSeeAllClick} count={mediaCount} />
				</div>
			)}
		</div>
	);
};
